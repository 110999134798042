import { Observable } from "rxjs";

export enum UserChipRenderTypeEnum {
    User = 1,
    Client = 2,
}

export interface UserChip {
    id?: number | string;
    name?: string;
    surname?: string;
    email?: string;
    sex?: string;
    titleBeforeName?: string;
    titleAfterName?: string;
    imgSrc$?: Observable<string>;
    renderType?: UserChipRenderTypeEnum;
    birthDate?: string;
    address?: object;
    addresses?: object[];
}
