<ng-container *ngIf="template; else defaultTemplate">
    <ng-container *ngTemplateOutlet="template; context: { data: value, item: item, rowIndex: rowIndex }">
    </ng-container>
</ng-container>

<ng-template #defaultTemplate>
    <div [ngSwitch]="type" [class.has-command]="column.commandCallback" (click)="column.commandCallback && column.commandCallback(item, $event)">
        <div *ngSwitchCase="TableColumnType.Date">{{ value | date: 'd.M.y' }}</div>
        <div *ngSwitchCase="TableColumnType.DateTime">{{ value | date: 'd.M.y hh:mm:ss' }}</div>
        <div *ngSwitchCase="TableColumnType.Time">{{ value | date: 'HH:MM' }}</div>
        <div *ngSwitchCase="TableColumnType.Age">{{ value | age }}</div>
        <div *ngSwitchCase="TableColumnType.Year">{{ value | year }}</div>
        <div *ngSwitchCase="TableColumnType.Tag">
            <p-tag *ngIf="value" [severity]="severity" [value]="column.tagOptions.labelPrefix + value | translate"
                [rounded]="true"></p-tag>
        </div>
        <app-user-chip *ngSwitchCase="TableColumnType.UserChip" [user]="value"
            [renderType]="column.userChipOptions?.renderType"></app-user-chip>
        <div *ngSwitchCase="TableColumnType.MapLink">
            <app-map-link *ngIf="value" [linkText]="value | formatAddress" [data]="value"></app-map-link>
        </div>
        <div *ngSwitchDefault>
            {{ value }}
        </div>
    </div>
</ng-template>
