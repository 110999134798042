import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
    FilterInput,
    FilterObject,
    GridInput,
    GridObject,
} from '@it2go/data-grid';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import { MenuItem, SortEvent } from 'primeng/api';
import { PaginatorState } from 'primeng/paginator';
import { TableColResizeEvent, TableColumnReorderEvent } from 'primeng/table';
import { firstValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import config from '../../../../../config/config';
import {
    selectLastAction,
    selectLastFailAction,
} from '../../../../../store/global/global.selectors';
import { gridGqlInput } from '../../../../content/service/types/filter.types';
import { SubscriptionComponent } from '../../../helpers/subscription.component';
import { refreshTable, setTableSetting } from '../../../store/shared.actions';
import {
    selectTableSettings,
    selectTableSettingsFetched,
} from '../../../store/shared.selectors';
import { TableSetting } from '../../../store/shared.state';
import { pluralize } from '../declension/pluralization.component';
import { stripPrefix } from '../../../store/common/common';
import { TableColumn, TableColumnType } from './table-v2.types';
import { LayoutService } from '../../../../layout/services/layout.service';
import { DEFAULTS, defaultString } from './table-v2.component.config';
import { TableFilterV2Component } from '../table-filter-v2/table-filter-v2.component';

@Component({
    selector: 'app-table-v2',
    templateUrl: './table-v2.component.html',
    styleUrls: ['./table-v2.component.scss'],
})
export class TableV2Component
    extends SubscriptionComponent
    implements OnInit, OnChanges
{
    @Input() containerClasses = '';
    @Input() subtitle: TemplateRef<any> | null = null;
    @Input() title = '';
    @Input() dataKey = 'id';
    @Input() tableKey?: string;
    @Input() disableCopyExport: boolean | string = false;

    @Input() translatePrefix: string | null = null;
    @Input() columns: TableColumn[] = [];
    visibleColumns: TableColumn[] = [];
    @Input() columnData: unknown[] = [];
    @Input() items: unknown[] | null = [];
    @Input() hideCheckboxes = false;
    selectedRows: any[] = [];
    @Output() selectedRowsChange = new EventEmitter<any[]>();
    selectedContextRow: any | null = null;

    @Input() gridData: GridObject | null = null;
    @Input() additionalFilter: FilterObject[][] | null = null;
    @Input() additionalForm: FormGroup | null = null;
    @Input() additionalFormArrays: string[] = [];
    @Output() additionalFilterChange = new EventEmitter<
        FilterObject[][] | null
    >();
    localGridData: GridInput = {};

    @Input() dialog: TemplateRef<any> | null = null;
    @Input() dialogButton: TemplateRef<any> | null = null;
    @Output() createCallback = new EventEmitter<any>();
    @Input() hideCreate = false;
    dialogData: unknown = null;
    dialogVisible = false;
    closeDialog = this.showDialog.bind(this, false);
    dialogTitle = 'create';

    @Output() detailCallback = new EventEmitter<any>();
    @Output() rowExpanded = new EventEmitter<any>();
    @Input() detailHeader: string = '';
    @Output() deleteCallback = new EventEmitter<any>();
    @Input() deleteHeader: string = '';
    @Input() refreshCallback: CallableFunction | null = null;

    @Input() rowExpansion: TemplateRef<any> | null = null;
    @Input() rowsPerPageOptions: any[] = [
        10,
        25,
        50,
        { showAll: this.translate.instant('all') },
    ];

    @Input() showExport: boolean = false;
    @Input() showColumnVisiblity: boolean = false;
    @Input() showSearch: boolean = false;

    @ViewChildren(TableFilterV2Component)
    filterComponents!: QueryList<TableFilterV2Component>;

    itemTemplates: Record<string, TemplateRef<any>> = {};
    footerTemplate?: TemplateRef<any>;
    lastSort = '';
    settings?: TableSetting;
    @Input() defaultSort: string | null = null;
    @Input() defaultSortDir: string = 'DESC';

    columnsVisibilityOptions: { name: string }[] = [];
    form = this.fb.group({
        columns: this.fb.control<{ name: string }[]>([]),
        search: this.fb.control<string | null>(null),
    });

    allowFiltering: boolean = false;

    protected showDetail: boolean = false;
    protected showDelete: boolean = false;
    protected showCreate: boolean = false;
    protected loading = new EventEmitter();
    protected isLoading = this.loading.pipe(debounceTime(100));

    protected bodyContextMenu: MenuItem[] = [
        { label: 'table.copy', icon: 'pi pi-copy', command: () => this.copy() },
        {
            label: 'table.exportSelected',
            icon: 'pi pi-file-import',
            items: [
                { label: 'export CSV', command: () => this.exportCSV() },
                { label: 'export XLS', command: () => this.exportExcel() },
                { label: 'export PDF', command: () => this.exportPdf() },
            ],
        },
        {
            label: 'table.exportAll',
            icon: 'pi pi-file-import',
            items: [
                { label: 'export CSV', command: () => this.exportCSV(true) },
                { label: 'export XLS', command: () => this.exportExcel(true) },
                { label: 'export PDF', command: () => this.exportPdf(true) },
            ],
        },
        {
            label: 'table.freezeRow',
            icon: 'pi pi-lock',
            command: () => this.freezeRow(this.selectedContextRow, true),
        },
        {
            label: 'table.freezeColumn',
            icon: 'pi pi-lock',
            command: () => this.freezeColumn(this.selectedContextRow, true),
        },
    ];
    protected frozenRowContextMenu: MenuItem[] = [
        { label: 'table.copy', icon: 'pi pi-copy', command: () => this.copy() },
        {
            label: 'table.exportSelected',
            icon: 'pi pi-file-import',
            items: [
                { label: 'export CSV', command: () => this.exportCSV() },
                { label: 'export XLS', command: () => this.exportExcel() },
                { label: 'export PDF', command: () => this.exportPdf() },
            ],
        },
        {
            label: 'table.exportAll',
            icon: 'pi pi-file-import',
            items: [
                { label: 'export CSV', command: () => this.exportCSV(true) },
                { label: 'export XLS', command: () => this.exportExcel(true) },
                { label: 'export PDF', command: () => this.exportPdf(true) },
            ],
        },
        {
            label: 'table.unfreeze',
            icon: 'pi pi-lock-open',
            command: () => this.freezeRow(this.selectedContextRow, false),
        },
    ];
    protected frozenColumnContextMenu: MenuItem[] = [
        { label: 'table.copy', icon: 'pi pi-copy', command: () => this.copy() },
        {
            label: 'table.exportSelected',
            icon: 'pi pi-file-import',
            items: [
                { label: 'export CSV', command: () => this.exportCSV() },
                { label: 'export XLS', command: () => this.exportExcel() },
                { label: 'export PDF', command: () => this.exportPdf() },
            ],
        },
        {
            label: 'table.exportAll',
            icon: 'pi pi-file-import',
            items: [
                { label: 'export CSV', command: () => this.exportCSV(true) },
                { label: 'export XLS', command: () => this.exportExcel(true) },
                { label: 'export PDF', command: () => this.exportPdf(true) },
            ],
        },
        {
            label: 'table.unfreeze',
            icon: 'pi pi-lock-open',
            command: () => this.freezeColumn(this.selectedContextRow, false),
        },
    ];
    contextMenu: MenuItem[] = this.bodyContextMenu;
    frozenRows: any[] = [];
    frozenColumns: TableColumn[] = [];

    private cols: TableColumn[] = [];

    get tableUniqueKey() {
        return (
            this.tableKey || stripPrefix(this.refreshCallback?.name || 'table')
        );
    }

    constructor(
        protected readonly store: Store,
        protected readonly actions$: Actions,
        protected readonly translate: TranslateService,
        protected readonly layoutService: LayoutService,
        protected readonly fb: FormBuilder,
    ) {
        super();
        this.loading.emit(false);
        this.bodyContextMenu.forEach((it) => {
            it.label = translate.instant(it.label!);
        });
        this.frozenRowContextMenu.forEach((it) => {
            it.label = translate.instant(it.label!);
        });
        this.frozenColumnContextMenu.forEach((it) => {
            it.label = translate.instant(it.label!);
        });
    }

    protected selectionChange(event: any) {
        this.selectedRowsChange.next(event);
    }

    async ngOnInit() {
        if (this.refreshCallback) {
            await this.refreshData(true);
        }

        this.cols = this.columns.map((col: TableColumn) => {
            const def = DEFAULTS.get(col.type || TableColumnType.String) || defaultString;

            if (def.filterable && col.filterable && typeof def.filterable !== 'boolean' && typeof col.filterable !== 'boolean') {
                col.filterable = { ...def.filterable, ...col.filterable };
            }

            return { ...def, ...col };
        });

        this.subs.push(
            this.store.select(selectTableSettingsFetched).subscribe((s) => {
                if (s) {
                    this.refreshData(true);
                }
            }),
            this.form.controls.search.valueChanges
                .pipe(debounceTime(config.debounceTime))
                .subscribe((search) => {
                    this.localGridData.search = search ? [search] : null;
                    this.refreshData();
                }),
            this.form.controls.columns.valueChanges.subscribe((visible) => {
                if (visible) {
                    let altered = false;
                    const visibles = new Set<string>();
                    visible.forEach((it) => visibles.add(it.name));
                    this.visibleColumns = this.visibleColumns.filter((it) => {
                        if (visibles.has(it.name)) {
                            visibles.delete(it.name);
                            return true;
                        }
                        altered = true;
                        return false;
                    });
                    visibles.forEach((it) => {
                        altered = true;
                        const originalIndex = this.cols.findIndex(
                            (x) => x.name === it,
                        );
                        this.visibleColumns.splice(
                            Math.min(originalIndex, this.visibleColumns.length),
                            0,
                            this.getColumnByName(it),
                        );
                    });

                    const columnsToDeleteFromSettings: string[] = [];
                    this.settings!.columnSettings = {
                        ...this.settings!.columnSettings,
                    };
                    Object.keys(this.settings!.columnSettings).forEach((it) => {
                        if (
                            this.cols.findIndex((x) => x.name === it) === -1
                        ) {
                            columnsToDeleteFromSettings.push(it);
                        }
                        this.settings!.columnSettings[it] = {
                            visible: false,
                            size: this.settings!.columnSettings[it]?.size ?? 0,
                        };
                    });
                    visible.forEach((it) => {
                        this.settings!.columnSettings[it.name] = {
                            visible: true,
                            size:
                                this.settings!.columnSettings[it.name]?.size ??
                                0,
                        };
                    });
                    columnsToDeleteFromSettings.forEach((it) => {
                        delete this.settings!.columnSettings[it];
                    });

                    if (this.refreshCallback && altered) {
                        this.store.dispatch(
                            setTableSetting({
                                table: this.tableUniqueKey,
                                columns: this.visibleColumns.map((x) => x.name),
                                columnSettings: this.settings!.columnSettings,
                            }),
                        );
                    }
                }
            }),
        );

        this.allowFiltering = this.cols.some(
            (x) => x.filterable !== undefined || x.filterable === true,
        );
    }

    isDate(value: unknown, column: string): boolean {
        return (
            Object.prototype.toString.call(value) === '[object Date]' ||
            column.startsWith('valid')
        );
    }

    showDialog(show = true, data: unknown = null) {
        this.detailCallback.emit(data);
        if (this.dialog) {
            this.dialogVisible = show;
            this.dialogData = data;
            this.dialogTitle = data ? 'detail' : 'create';
        }
    }

    pageChange(paginator: PaginatorState) {
        this.localGridData = gridGqlInput(this.gridData);
        this.localGridData!.paging!.page = (paginator.page || 0) + 1;
        this.localGridData!.paging!.itemsPerPage = paginator.rows || 10;
        this.refreshData();
    }

    sortChange(sorter: SortEvent) {
        const sort = `${sorter.field}_${sorter.order}`;
        if (this.lastSort == sort) return;
        this.lastSort = sort;

        this.localGridData = gridGqlInput(this.gridData);
        this.localGridData!.sorter = [
            {
                column: sorter.field || '',
                direction: (sorter.order || 0) > 0 ? 'ASC' : 'DESC',
            },
        ];
        this.refreshData();
    }

    filterChange(filter: FilterInput) {
        const valuesNotEmpty = function (filter: FilterInput) {
            return (
                filter.value[0] &&
                (!['BETWEEN', 'NBETWEEN'].includes(filter.operator) ||
                    filter.value[1])
            );
        };

        this.localGridData = gridGqlInput(this.gridData);
        if (!this.localGridData?.filter) this.localGridData.filter = [];

        const existing = this.localGridData?.filter?.findIndex(
            (item) => item[0].column == filter.column,
        );
        if (existing !== undefined && existing >= 0) {
            if (valuesNotEmpty(filter)) {
                this.localGridData!.filter[existing][0] = filter;
            } else {
                this.localGridData!.filter = this.localGridData!.filter?.filter(
                    (item, index) => index != existing,
                );
            }
        } else if (valuesNotEmpty(filter)) {
            this.localGridData?.filter?.push([filter]);
        }
        this.refreshData();
    }

    clearSelection() {
        this.selectedRows = [];
        this.selectedRowsChange.next([]);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['gridData']?.currentValue) {
            // To get rid of Angular's read-only state
            this.localGridData = gridGqlInput(changes['gridData'].currentValue);
            this.items = changes['gridData']?.currentValue?.['items'] || [];
            if (this.refreshCallback) {
                this.store.dispatch(
                    setTableSetting({
                        table: this.tableUniqueKey,
                        filter: gridGqlInput(
                            changes['gridData']?.currentValue,
                            this.additionalFilter,
                        ),
                    }),
                );
            }
            this.loading.emit(false);
        }
        if (
            changes['additionalFilter'] &&
            !changes['additionalFilter'].firstChange
        ) {
            this.refreshData();
        }
        if (changes['items']?.currentValue) {
            this.clearSelection();
        }

        this.showDetail = this.detailCallback.observed;
        this.showDelete = this.deleteCallback.observed;
        this.showCreate = this.createCallback.observed;
    }

    clearFilters() {
        this.filterComponents.forEach((comp) => comp.clearValue());
        this.localGridData.filter = null;
        this.refreshData();
    }

    getFilter(column: string) {
        for (const inner of this.settings?.filter?.filter || []) {
            for (const it of inner) {
                if (it.column === column) {
                    return it;
                }
            }
        }

        return null;
    }

    async refreshData(fromStore: boolean = false) {
        const setting = await firstValueFrom(
            this.store.select(selectTableSettings(this.tableUniqueKey)),
        );

        this.settings = JSON.parse(JSON.stringify(setting)) as any;
        if (this.refreshCallback) {
            this.frozenRows = [];
            this.loading.emit(true);
            const filter = gridGqlInput(
                this.localGridData,
                this.additionalFilter,
            );
            if (
                fromStore ||
                JSON.stringify(setting!.filter) != JSON.stringify(filter)
            ) {
                if (fromStore) {
                    const defaults: GridInput = {};
                    if (
                        !setting?.filter?.sorter ||
                        !setting.filter.sorter.length
                    ) {
                        if (this.defaultSort) {
                            defaults.sorter = [
                                {
                                    column: this.defaultSort,
                                    direction: this.defaultSortDir,
                                },
                            ];
                        }
                    }
                    this.refreshCallback({
                        tableKey: this.tableKey,
                        ...(setting?.filter || {}),
                        ...defaults,
                        additionalFilter: filter.additionalFilter,
                    });
                    this.additionalFilterChange.emit(
                        setting?.filter?.additionalFilter || null,
                    );
                    (setting?.filter?.additionalFilter || [[]]).forEach(
                        (its) => {
                            its.forEach((it) => {
                                if (
                                    this.additionalFormArrays.includes(
                                        it.column,
                                    )
                                ) {
                                    this.additionalForm?.patchValue({
                                        [it.column]: it.value,
                                    });
                                } else {
                                    this.additionalForm?.patchValue({
                                        [it.column]: it.value[0],
                                    });
                                }
                            });
                        },
                    );
                } else {
                    if (!filter.sorter || !filter.sorter.length) {
                        if (this.defaultSort) {
                            filter.sorter = [
                                {
                                    column: this.defaultSort,
                                    direction: this.defaultSortDir,
                                },
                            ];
                        }
                    }

                    this.refreshCallback({
                        ...filter,
                        tableKey: this.tableUniqueKey,
                    });
                    this.store.dispatch(
                        setTableSetting({
                            table: this.tableUniqueKey,
                            filter: filter,
                        }),
                    );
                }
            } else {
                this.loading.emit(false);
            }
        }

        if (!this.columnsVisibilityOptions.length) {
            this.columnsVisibilityOptions = this.cols.map((it) => {
                return { name: it.name };
            });
        }

        if (setting?.columns?.length) {
            const defaults = this.cols.reduce(
                (acc, it) => {
                    acc[it.name] = false;
                    return acc;
                },
                {} as Record<string, boolean>,
            );

            this.visibleColumns = setting?.columns
                .filter((it) => {
                    if (!(it in defaults)) return false;
                    defaults[it] = true;
                    return setting?.columnSettings?.[it]?.visible ?? true;
                })
                .map((name) => this.getColumnByName(name));
            Object.entries(defaults).forEach(([it, ok]) => {
                if (!ok) {
                    const originalIndex = this.cols.findIndex(
                        (x) => x.name === it,
                    );
                    this.visibleColumns.splice(
                        Math.min(originalIndex, this.visibleColumns.length),
                        0,
                        this.getColumnByName(it),
                    );
                }
            });

            this.form.patchValue({
                columns: this.visibleColumns
                    .filter((it) => {
                        return (
                            (setting &&
                                setting.columnSettings &&
                                setting.columnSettings[it.name] ===
                                    undefined) ||
                            setting?.columnSettings?.[it.name].visible
                        );
                    })
                    .map((item) => ({ name: item.name })),
            });
        } else {
            this.form.patchValue({
                columns: this.columnsVisibilityOptions,
            });
        }
    }

    public async reload() {
        if (!this.refreshCallback) return;
        this.frozenRows = [];
        this.loading.emit(true);
        const setting = await firstValueFrom(
            this.store.select(selectTableSettings(this.tableUniqueKey)),
        );
        this.refreshCallback({
            ...setting!.filter,
            tableKey: this.tableUniqueKey,
        });
    }

    public override ngAfterContentInit(): void {
        this.templates.forEach((item) => {
            switch (item.getType()) {
                case 'footer':
                    this.footerTemplate = item.template;
                    break;
                default:
                    this.itemTemplates[item.getType()] = item.template;
            }
        });
        if (this.refreshCallback) {
            this.subs.push(
                this.actions$
                    .pipe(ofType(refreshTable))
                    .subscribe(async ({ table }) => {
                        if (table == this.refreshCallback?.name) {
                            await this.reload();
                        }
                    }),
                this.store.select(selectLastAction).subscribe(({ key }) => {
                    if (key == this.refreshCallback?.name) {
                        this.loading.emit(false);
                    }
                }),
                this.store.select(selectLastFailAction).subscribe((key) => {
                    if (key == this.refreshCallback?.name) {
                        this.loading.emit(false);
                    }
                }),
            );
        }
    }

    protected tableSummary(grid: GridObject | null): string {
        if (!grid) return '';
        const perPage = grid.paging?.itemsPerPage || 1;
        const total = grid.paging?.total || 0;
        let page = grid.paging?.page || 1;

        let summary = this.translate
            .instant('table.tableSummary')
            .replace('{to}', Math.min(page * perPage, total))
            .replace('{from}', total == 0 ? 0 : --page * perPage + 1)
            .replace('{total}', total);
        if (this.selectedRows.length) {
            summary += `, ${pluralize(
                this.selectedRows.length,
                'selectedRows',
                this.translate,
            )}`;
        }

        return summary;
    }

    tableReorder(event: TableColumnReorderEvent) {
        if (event.dragIndex !== null && event.dropIndex !== null) {
            const column = this.visibleColumns.splice(event.dragIndex!, 1)[0];
            this.visibleColumns.splice(event.dropIndex!, 0, column);
            if (this.refreshCallback) {
                const allColumns = this.frozenColumns.concat(
                    this.visibleColumns,
                );
                this.store.dispatch(
                    setTableSetting({
                        table: this.tableUniqueKey,
                        columns: allColumns.map((x) => x.name),
                    }),
                );
            }
        }
    }

    columnResize(event: TableColResizeEvent) {
        if (this.refreshCallback) {
            const column = event.element.getAttribute('colname')!;
            this.settings!.columnSettings[column] = {
                visible: this.settings!.columnSettings[column]?.visible ?? 1,
                size: event.element.clientWidth,
            };
            this.store.dispatch(
                setTableSetting({
                    table: this.refreshCallback!.name,
                    columnSettings: { ...this.settings!.columnSettings },
                }),
            );
        }
    }

    getColumnSize(column: string) {
        const size = this.settings?.columnSettings?.[column];

        return size ? `${size}px` : 'unset';
    }

    freezeRow(
        data: { column: string; rowIndex: number; item: any },
        freeze: boolean,
    ) {
        if (freeze) {
            this.frozenRows.push({ ...data.item, rowIndex: data.rowIndex });
            // this.items = this.items?.slice(0, data.rowIndex).concat(this.items?.slice(data.rowIndex + 1)) || [];
        } else {
            // const row = this.frozenRows[data.rowIndex];
            this.frozenRows = this.frozenRows
                .slice(0, data.rowIndex)
                .concat(this.frozenRows.slice(data.rowIndex + 1));
            // this.items = this.items?.slice(0, row.rowIndex).concat(row).concat(this.items?.slice(row.rowIndex + 1)) || [];
        }
    }

    freezeColumn(
        data: { column: string; rowIndex: number; item: any },
        freeze: boolean,
    ) {
        if (freeze) {
            const index = this.visibleColumns.findIndex(
                (x) => x.name === data.column,
            );
            this.frozenColumns.push(this.visibleColumns[index]);
            this.visibleColumns.splice(index, 1);
        } else {
            const index = this.frozenColumns.findIndex(
                (x) => x.name === data.column,
            );
            this.visibleColumns.unshift(this.frozenColumns[index]);
            this.frozenColumns.splice(index, 1);
        }
    }

    changeContextMenu(items: MenuItem[]) {
        if (this.selectedRows.length) {
            this.contextMenu = items.slice(0, 2).concat(items.slice(3));
        } else {
            this.contextMenu = items;
        }
    }

    @HostListener('document:keydown.control.c', ['$event'])
    copy(event?: KeyboardEvent) {
        if (this.disableCopyExport !== false && !!event) return;
        const content = this.formatAsCsv(
            (this.selectedRows.length ? this.selectedRows : this.items) || [],
        );
        navigator.clipboard.writeText(content);
        event?.preventDefault();
    }

    @HostListener('document:keydown.control.a', ['$event'])
    selectAll(event: KeyboardEvent) {
        this.selectedRows = this.selectedRows.concat(this.items);
        this.selectedRows = this.selectedRows.filter(
            (it, index) =>
                index == this.selectedRows.findIndex((itt) => itt == it),
        );
        event.preventDefault();
    }

    matchPreceding(item: any, column: string, index: number): boolean {
        const col = this.lastSort.split('_')[0];
        if (index > 0 && col === column) {
            const previousItem = this.items?.[index - 1] as any;
            if (
                new RegExp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}T.+$/).test(item[column])
            ) {
                const first = new Date(item[column] ?? '');
                const second = new Date(previousItem?.[column] ?? '');

                return (
                    first.getFullYear() === second.getFullYear() &&
                    first.getMonth() === second.getMonth() &&
                    first.getDate() === second.getDate()
                );
            }
            return item[column] == previousItem?.[column];
        }

        return false;
    }

    isStripped(item: any, column: string, index: number): boolean {
        const i = index;
        // while (this.matchPreceding(item, column, i)) {
        //     i--;
        // }

        return !!(i % 2) && !this.selectedRows.includes(item);
    }

    exportCSV(all: boolean = false) {
        const content = this.formatAsCsv(
            (this.selectedRows.length && !all
                ? this.selectedRows
                : this.items) || [],
        );
        const blob = new Blob([content], { type: 'text/csv;charset=utf-8,' });
        const objUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', objUrl);
        link.setAttribute('download', 'export.csv');
        document.body.appendChild(link);
        link.click();
    }

    private formatAsCsv(items: any[]) {
        const header: string[] = [];
        const rows: string[] = [];

        items.map((it, index) => {
            const row: string[] = [];
            this.visibleColumns.forEach((col) => {
                if (col.name in it) {
                    if (index === 0) {
                        header.push(col.name);
                    }
                    switch (it[col.name]?.__typename || '') {
                        case 'AddressDetailObject': {
                            const address = it[col.name];
                            row.push(`"${address.name}, ${address.location}"`);
                            break;
                        }
                        default:
                            row.push(`"${it[col.name]}"`);
                    }
                }
            });
            if (index === 0) {
                rows.push(header.join(';'));
            }
            rows.push(row.join(';'));
        });

        return rows.join('\n');
    }

    exportPdf(all: boolean = false) {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then((x) => {
                const doc = new jsPDF.default('p', 'px', 'a4');
                (doc as any).autoTable(
                    this.visibleColumns,
                    (this.selectedRows.length && !all
                        ? this.selectedRows
                        : this.items || []
                    ).map((it) => {
                        return this.visibleColumns.map((col) => it[col.name]);
                    }),
                );
                doc.save('products.pdf');
            });
        });
    }

    exportExcel(all: boolean = false) {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(
                this.selectedRows.length && !all
                    ? this.selectedRows
                    : this.items || [],
            );
            const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ['data'],
            };
            const excelBuffer: any = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            });
            this.saveAsExcelFile(excelBuffer, 'products');
        });
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
        });
        FileSaver.saveAs(
            data,
            fileName + '_export_' + new Date().getTime() + '.xlsx',
        );
    }

    private getColumnByName(name: string): TableColumn {
        return this.cols.find((x) => x.name === name)!;
    }
    protected readonly debounceTime = debounceTime;
}
