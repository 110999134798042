import { EmploymentGroupEnum } from './employment-group.enum';

export enum EmploymentEnum {
    SOCIAL_WORKER = 'socialWorker',
    DIRECT_CARE = 'directCare',
    NON_PEDAGOGICAL_EDUCATION = 'nonPedagogicalEducation',
    NURSING = 'nursing',
    SUPERVISED_BY_SOCIAL_WORKER = 'supervisedBySocialWorker',
    DOCTOR = 'doctor',
    GENERAL_NURSE_UNSUPERVISED = 'generalNurseUnsupervised',
    GENERAL_NURSE_SUPERVISED = 'generalNurseSupervised',
    SENIOR_NURSE = 'seniorNurse',
    SENIOR_NURSE_SPECIALIZATION = 'seniorNurseSpecialization',
    GENERAL_NURSE_SPECIALIZATION = 'generalNurseSpecialization',
    PRACTICAL_NURSE = 'practicalNurse',
    GENERAL_SOCIAL_NURSE = 'generalSocialNurse',
    MIDWIFE = 'midwife',
    MEDICAL_ASSISTANT = 'medicalAssistant',
    PHYSIOTHERAPIST = 'physiotherapist',
    OCCUPATIONAL_THERAPIST = 'occupationalTherapist',
    HEALTH_SOCIAL_WORKER = 'healthSocialWorker',
    NUTRITIONIST = 'nutritionist',
    ADDICTOLOGIST = 'addictologist',
    CARETAKER = 'caretaker',
    ORDERLY = 'orderly',
    OTHER_PROF_WORKER = 'otherProfWorker',
    OTHER_NON_LISTED_WORKER = 'otherNonListedWorker',
    TEACHER = 'teacher',
    TUTOR = 'tutor',
    SPECIAL_EDU = 'specialEdu',
    PSYCHOLOGIST = 'psychologist',
    FREE_TIME_EDU = 'freeTimeEdu',
    TEACHING_ASSISTANT = 'teachingAssistant',
    COACH = 'coach',
    SENIOR_PEDAGOGIC_WORKER = 'seniorPedagogicWorker',
    MARRIAGE_FAMILY_COUNSELORS = 'marriageFamilyCounselors',
    OTHER_PROF_SOCIAL_WORKERS = 'otherProfSocialWorkers',
    LAUNDRY = 'laundry',
    CATERING = 'catering',
    MAINTENANCE = 'maintenance',
    CLEANING = 'cleaning',
    OTHER_SERVICE_STAFF = 'otherServiceStaff',
    HEAD_OF_ORGANIZATION = 'headOfOrganization',
    HEAD_OF_SERVICE = 'headOfService',
    OTHER_EXECUTIVE = 'otherExecutive',
    SECRETARIAL_ASSISTANT = 'secretarialAssistant',
    ACCOUNTANT = 'accountant',
    OTHER_ADMINISTRATIVE_STAFF = 'otherAdministrativeStaff',
    VOLUNTEER = 'volunteer',
}

export const employmentGroup = {
    [EmploymentGroupEnum.SOCIAL_WORKER]: [ // 1.PP
        EmploymentEnum.SOCIAL_WORKER,
    ],
    [EmploymentGroupEnum.WORKERS_IN_SOCIAL_SERVICES]: [ // 2.PP
        EmploymentEnum.DIRECT_CARE,
        EmploymentEnum.NON_PEDAGOGICAL_EDUCATION,
        EmploymentEnum.NURSING,
        EmploymentEnum.SUPERVISED_BY_SOCIAL_WORKER,
    ],
    [EmploymentGroupEnum.MEDICAL_WORKERS]: [ // 3.PP
        EmploymentEnum.DOCTOR,
    ],
    [EmploymentGroupEnum.NON_MEDICAL_HEALTH_WORKERS]: [ // 4.PP
        EmploymentEnum.GENERAL_NURSE_UNSUPERVISED,
        EmploymentEnum.GENERAL_NURSE_SUPERVISED,
        EmploymentEnum.SENIOR_NURSE,
        EmploymentEnum.SENIOR_NURSE_SPECIALIZATION,
        EmploymentEnum.GENERAL_NURSE_SPECIALIZATION,
        EmploymentEnum.PRACTICAL_NURSE,
        EmploymentEnum.GENERAL_SOCIAL_NURSE,
        EmploymentEnum.MIDWIFE,
        EmploymentEnum.MEDICAL_ASSISTANT,
        EmploymentEnum.PHYSIOTHERAPIST,
        EmploymentEnum.OCCUPATIONAL_THERAPIST,
        EmploymentEnum.HEALTH_SOCIAL_WORKER,
        EmploymentEnum.NUTRITIONIST,
        EmploymentEnum.ADDICTOLOGIST,
        EmploymentEnum.CARETAKER,
        EmploymentEnum.ORDERLY,
        EmploymentEnum.OTHER_PROF_WORKER,
        EmploymentEnum.OTHER_NON_LISTED_WORKER,
    ],
    [EmploymentGroupEnum.TEACHING_STAFF]: [
        EmploymentEnum.TEACHER,
        EmploymentEnum.TUTOR,
        EmploymentEnum.SPECIAL_EDU,
        EmploymentEnum.PSYCHOLOGIST,
        EmploymentEnum.FREE_TIME_EDU,
        EmploymentEnum.TEACHING_ASSISTANT,
        EmploymentEnum.COACH,
        EmploymentEnum.SENIOR_PEDAGOGIC_WORKER,
    ],
    [EmploymentGroupEnum.MARRIAGE_FAMILY_COUNSELORS]: [
        EmploymentEnum.MARRIAGE_FAMILY_COUNSELORS,
    ],
    [EmploymentGroupEnum.OTHER_PROF_SOCIAL_WORKERS]: [
        EmploymentEnum.OTHER_PROF_SOCIAL_WORKERS,
    ],
    [EmploymentGroupEnum.SERVICE_STAFF]: [
        EmploymentEnum.LAUNDRY,
        EmploymentEnum.CATERING,
        EmploymentEnum.MAINTENANCE,
        EmploymentEnum.CLEANING,
        EmploymentEnum.OTHER_SERVICE_STAFF,
    ],
    [EmploymentGroupEnum.EXECUTIVES]: [
        EmploymentEnum.HEAD_OF_ORGANIZATION,
        EmploymentEnum.HEAD_OF_SERVICE,
        EmploymentEnum.OTHER_EXECUTIVE,
    ],
    [EmploymentGroupEnum.ADMINISTRATIVE_STAFF]: [
        EmploymentEnum.SECRETARIAL_ASSISTANT,
        EmploymentEnum.ACCOUNTANT,
        EmploymentEnum.OTHER_ADMINISTRATIVE_STAFF,
    ],
    [EmploymentGroupEnum.VOLUNTEERS]: [
        EmploymentEnum.VOLUNTEER,
    ],
};
