import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserGridItemObject } from '@it2go/types';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import config from '../../../../../config/config';
import { SubscriptionComponent } from '../../../../shared/helpers/subscription.component';
import { InputUserMultiselectStore } from './input-user-multiselect.store';
import { EmploymentGroupEnum } from '../../../../../../../../types/src/user/enum/employment-group.enum';
import { employmentGroup } from '../../../../../../../../types/src/user/enum/employment.enum';
import { FilterBuilder } from '../../../../content/helper/filter.builder';

@Component({
    selector: 'app-input-user-multiselect',
    templateUrl: 'input-user-multiselect.component.html',
    styleUrls: ['input-user-multiselect.component.scss'],
    providers: [InputUserMultiselectStore],
})
export class InputUserMultiselectComponent extends SubscriptionComponent implements OnInit, OnChanges {

    @Input() doctorOnly = false;
    @Input() form!: FormGroup;
    @Input() id = 'userId';
    @Input() label = '';
    @Input() serviceId: number | null = null;
    @Input() employment: string | null = null;

    // null - uses current organizationId
    // empty array - no filter
    @Input() organizationIds: number[] | null = null;
    @Input() notInOrganizationIds: number[] = [];

    searchSubject = new Subject<string>();
    lastSearch: string | null = null;

    constructor(
        protected readonly store: InputUserMultiselectStore,
    ) {
        super();
        this.fetchUsers();
        this.subs.push(
            this.searchSubject
                .pipe(debounceTime(config.debounceTime))
                .subscribe((search) => this.fetchUsers(search)),
        );
    }

    async ngOnInit(): Promise<void> {
        this.subs.push(
            this.form.controls[this.id].valueChanges.subscribe(async (userIds: string[] | null) => {
                if (userIds) {
                    const users = await this.store.get(this.store.users$);
                    if (!users.length) {
                        const fb = new FilterBuilder();
                        this.store.api.getUsers(fb.where('id', userIds, 'IN').filter());
                    }
                }
            }),
        );
    }

    filter(search: string) {
        this.searchSubject.next(search);
    }

    protected async fetchUsers(search: string | null = null): Promise<void> {
        const filter = new FilterBuilder()
            .search(search)
            .limit(100);
        this.lastSearch = search;
        if (this.doctorOnly) {
            filter.where('doctor', 'true');
        }
        if (this.serviceId) {
            filter.where('serviceId', this.serviceId.toString());
        }
        if (this.employment) {
            if (Object.values(EmploymentGroupEnum).includes(this.employment as EmploymentGroupEnum)) {
                filter.where('employment', employmentGroup[this.employment as EmploymentGroupEnum], 'IN');
            } else {
                filter.where('employment', this.employment);
            }
        }
        if (this.organizationIds !== null) {
            if (this.organizationIds.length) {
                filter.where('organizationId', this.organizationIds, 'IN');
            }
        } else {
            filter.where('organizationId', this.store.organizationId);
        }
        if (this.notInOrganizationIds.length) {
            filter.where('organizationId', this.notInOrganizationIds, 'NIN');
        }

        await this.store.api.getUsers(filter.filter());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        this.doctorOnly = !!changes['doctorOnly']?.currentValue;
        this.fetchUsers(this.lastSearch);
    }

}
