import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { combineLatest, first, from } from 'rxjs';

@Injectable()
export class ConfirmBuilder {

    constructor(
        private readonly translate: TranslateService,
        private readonly confirmSvc: ConfirmationService,
    ) {
    }

    public confirm(
        header: string,
        message: string | null,
        callback: Function,
        replacements: Record<string, string | number> | object = {},
        icon: string = 'pi-trash',
    ) {
        combineLatest([
            this.translate.get(header),
            message ? this.translate.get(message) : from(''),
        ])
            .pipe(first())
            .subscribe(([header, message]) => {
                Object.entries(replacements).forEach(([key, value]) => {
                    key = `{{${key}}}`;
                    value = (typeof value === 'string') ? this.translate.instant(value) : value;
                    header = header.replaceAll(key, value);
                    message = message.replaceAll(key, value);
                });

                this.confirmSvc.confirm({
                    header,
                    message,
                    icon: `pi ${icon}`,
                    accept: callback,
                });
            });
    }

    public confirmPrep(
        prefix: string,
        callback: Function,
        replacements: Record<string, string | number> = {},
        icon: string = 'pi-trash',
    ) {
        return this.confirm(
            `${prefix}Header`,
            `${prefix}Message`,
            callback,
            replacements,
            icon,
        );
    }

}
