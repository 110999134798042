import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-folder-form-data-exists',
    templateUrl: './folder-form-data-exists.component.html',
})
export class FolderFormDataExistsComponent {
    @Input({ required: true }) form!: FormGroup;
    @Input({ required: true }) id!: string;

}
