import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputMaskModule } from 'primeng/inputmask';
import { InputPhoneComponent } from './components/inputs/input-phone/input-phone.component';
import {
    InputTextAutocompleteComponent,
} from './components/inputs/input-text-autocomplete/input-text-autocomplete.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { TagModule } from 'primeng/tag';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ChipModule } from 'primeng/chip';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InvalidEmailComponent } from './components/error/invalid-email/invalid-email.component';
import { DateOccupiedComponent } from './components/error/date-occupied/date-occupied.component';
import { MinValueErrorComponent } from './components/error/min-value-error/min-value-error.component';
import { MaxValueErrorComponent } from './components/error/max-value-error/max-value-error.component';
import { MinLengthErrorComponent } from './components/error/min-length-error/min-length-error.component';
import { MaxLengthErrorComponent } from './components/error/max-length-error/max-length-error.component';
import { OnlyNumbersErrorComponent } from './components/error/only-numbers-error/only-numbers-error.component';
import { RequiredErrorComponent } from './components/error/required-error/required-error.component';
import { InputCheckComponent } from './components/inputs/input-check/input-check.component';
import { InputDateComponent } from './components/inputs/input-date/input-date.component';
import { InputFulltextComponent } from './components/inputs/input-fulltext/input-fulltext.component';
import { InputPasswordComponent } from './components/inputs/input-password/input-password.component';
import { InputRadioComponent } from './components/inputs/input-radio/input-radio.component';
import { InputSelectButtonComponent } from './components/inputs/input-select-button/input-select-button.component';
import { InputSelectComponent } from './components/inputs/input-select/input-select.component';
import { InputSwitchDepreComponent } from './components/inputs/input-switch-depre/input-switch-depre.component';
import { InputSwitchComponent } from './components/inputs/input-switch/input-switch.component';
import { InputTextAreaComponent } from './components/inputs/input-text-area/input-text-area.component';
import { InputEmailComponent } from './components/inputs/input-text/input-email.component';
import { InputNumberComponent } from './components/inputs/input-text/input-number.component';
import { InputSearchComponent } from './components/inputs/input-text/input-search.component';
import { InputTextComponent } from './components/inputs/input-text/input-text.component';
import { InputWebComponent } from './components/inputs/input-text/input-web.component';
import { InputWysiwygComponent } from './components/inputs/input-wysiwyg/input-wysiwyg.component';
import { InputExistingComponent } from './components/inputs/input-existing/input-existing.component';
import { InputSelectEntityComponent } from './components/inputs/input-select-entity/input-select-entity.component';
import { ButtonComponent } from './components/kit/button/button.component';
import { CancelButtonComponent } from './components/kit/button/cancel-button.component';
import { ContinueButtonComponent } from './components/kit/button/continue-button.component';
import {
    InputSelectButtonSexComponent,
} from './components/inputs/input-select-button/input-select-button-sex.component';
import { SaveButtonComponent } from './components/kit/button/save-button.component';
import { ConfirmationComponent } from './components/kit/confirmation/confirmation.component';
import { FloatingErrorMessageComponent } from './components/kit/floating-message/floating-error-message.component';
import { FloatingMessageComponent } from './components/kit/floating-message/floating-message.component';
import { FloatingWarnMessageComponent } from './components/kit/floating-message/floating-warn-message.component';
import { InputAddressComponent } from './components/inputs/input-address/input-address.component';
import { HeaderComponent } from './components/kit/header/header.component';
import { ListCardComponent } from './components/kit/list-card/list-card.component';
import { TableFilterComponent } from './components/kit/table-filter/table-filter.component';
import { TableFilterV2Component } from './components/kit/table-filter-v2/table-filter-v2.component';
import { CardTableComponent } from './components/kit/table/card-table.component';
import { TableComponent } from './components/kit/table/table.component';
import { TableV2Component } from './components/kit/table-v2/table-v2.component';
import { AgePipe } from './pipes/age.pipe';
import { CurrentValidPipe } from './pipes/currentValid.pipe';
import { FormatPersonPipe } from './pipes/format-person.pipe';
import { FormatSizePipe } from './pipes/format-size.pipe';
import { GenerateInputIdPipe } from './pipes/generate-input-id.pipe';
import { LocalDatePipe } from './pipes/local-date.pipe';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { ShortTimePipe } from './pipes/short-time.pipe';
import { YearPipe } from './pipes/year.pipe';
import { InputSelectSearchComponent } from './components/inputs/input-select-search/input-select-search.component';
import { InputDateScrollerComponent } from './components/inputs/input-date-scroller/input-date-scroller.component';
import { TranslateOrPipe } from './pipes/translateOr.pipe';
import { PluralizationComponent } from './components/kit/declension/pluralization.component';
import { AvatarComponent } from './components/kit/avatar/avatar.component';
import { StoreModule } from '@ngrx/store';
import { UserService } from './service/user.service';
import { sharedFeatureKey } from './store/shared.state';
import { sharedReducer } from './store/shared.reducer';
import { userFeatureKey } from '../content/store/user/user.state';
import { userReducer } from '../content/store/user/user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { SharedEffects } from './store/shared.effects';
import { MapComponent } from './components/map/map.component';
import { MapService } from './components/map/map.service';
import { FormatAddressPipe } from './pipes/format-address.pipe';
import { InputRadioYesNoComponent } from './components/inputs/input-radio/input-radio-yes-no.component';
import { InputMultiSelectComponent } from './components/inputs/input-multi-select/input-multi-select.component';
import { FormatPersonContactPipe } from './pipes/format-person-contact.pipe';
import { InnerModalComponent } from './components/kit/inner-modal/inner-modal.component';
import { RippleModule } from 'primeng/ripple';
import { ContextMenuModule } from 'primeng/contextmenu';
import { JoditWrapperComponent } from './components/wysiwyg/jodit-wrapper/jodit-wrapper.component';
import { MapLinkComponent } from './components/map-link/map-link.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NgxJoditProComponent } from 'ngx-jodit-pro';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { SumItemsPipe } from './pipes/sum-items.pipe';
import { BatchNumberExistsComponent } from './components/error/batch-number-exists/batch-number-exists.component';
import { InvoiceNumberExistsComponent } from './components/error/invoice-number-exists/invoice-number-exists.component';
import { CheckOrCrossComponent } from './components/check-or-cross/check-or-cross.component';
import { AuditLogTreeComponent } from './components/audit-log-tree/audit-log-tree.component';
import { TreeModule } from 'primeng/tree';
import { ConfirmBuilder } from './service/confirm.builder';
import { FormArrayWrapComponent } from './components/form-array-wrap/form-array-wrap.component';
import { ButtonDeleteComponent } from './components/kit/button/button-delete.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FileService } from './service/file.service';
import { UserChipComponent } from './components/kit/user-chip/user-chip.component';
import { TableCellContentComponent } from './components/kit/table-v2/components/table-cell-content/table-cell-content.component';
import { InputUserMultiselectComponent } from './components/inputs/input-user-multiselect/input-user-multiselect.component';
import { FormatVoucherPipe } from './pipes/format-voucher.pipe';
import { FormatVoucherValidityPipe } from './pipes/format-voucher-validity.pipe';
import { CardComponent } from './components/kit/card/card.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { LoadingComponent } from './components/kit/loading/loading.component';
import { StripAndShowComponent } from './components/kit/strip-and-show/strip-and-show.component';
import {
    FolderFormDataExistsComponent,
} from './components/error/folder-form-data-exists/folder-form-data-exists.component';

@NgModule({
    declarations: [
        InputSwitchComponent,
        InputSwitchDepreComponent,
        InputDateComponent,
        InputFulltextComponent,
        InputWysiwygComponent,
        InputEmailComponent,
        InputWebComponent,
        InputPhoneComponent,
        InputPasswordComponent,
        InputTextComponent,
        InputNumberComponent,
        InputTextAreaComponent,
        InputSelectComponent,
        InputRadioComponent,
        InputSelectButtonComponent,
        InputSearchComponent,
        InputExistingComponent,
        InputSelectEntityComponent,
        HeaderComponent,
        ListCardComponent,
        CardComponent,
        TableComponent,
        CardTableComponent,
        TableV2Component,
        TableCellContentComponent,
        InvalidEmailComponent,
        DateOccupiedComponent,
        BatchNumberExistsComponent,
        InvoiceNumberExistsComponent,
        InputCheckComponent,
        MinValueErrorComponent,
        MaxValueErrorComponent,
        MinLengthErrorComponent,
        MaxLengthErrorComponent,
        OnlyNumbersErrorComponent,
        RequiredErrorComponent,
        InputSelectButtonSexComponent,
        TableFilterComponent,
        TableFilterV2Component,
        ButtonComponent,
        CancelButtonComponent,
        ContinueButtonComponent,
        ButtonDeleteComponent,
        SaveButtonComponent,
        ConfirmationComponent,
        FloatingMessageComponent,
        FloatingErrorMessageComponent,
        FloatingWarnMessageComponent,
        FloatingMessageComponent,
        InputAddressComponent,
        InputTextAutocompleteComponent,
        InputUserMultiselectComponent,
        MapComponent,
        MapLinkComponent,
        InputSelectSearchComponent,
        InputDateScrollerComponent,
        PluralizationComponent,
        AvatarComponent,
        UserChipComponent,
        InputRadioYesNoComponent,
        InputMultiSelectComponent,
        InnerModalComponent,
        JoditWrapperComponent,
        LoadingComponent,
        CheckOrCrossComponent,
        AuditLogTreeComponent,
        FormArrayWrapComponent,
        StripAndShowComponent,
        FolderFormDataExistsComponent,
    ],
    exports: [
        AngularSvgIconModule,
        TimelineModule,
        DynamicDialogModule,
        TableComponent,
        CardTableComponent,
        TableV2Component,
        TableCellContentComponent,
        InputSwitchComponent,
        InputSwitchDepreComponent,
        InputDateComponent,
        InputFulltextComponent,
        InputWysiwygComponent,
        InputEmailComponent,
        InputWebComponent,
        InputPhoneComponent,
        HeaderComponent,
        InputPasswordComponent,
        InputTextComponent,
        InputNumberComponent,
        InputSearchComponent,
        InputExistingComponent,
        InputSelectEntityComponent,
        InvalidEmailComponent,
        DateOccupiedComponent,
        BatchNumberExistsComponent,
        InvoiceNumberExistsComponent,
        InputCheckComponent,
        ListCardComponent,
        CardComponent,
        InputSelectComponent,
        LoadingComponent,
        InputRadioComponent,
        InputTextAreaComponent,
        InputSelectButtonComponent,
        InputSelectButtonSexComponent,
        MinValueErrorComponent,
        MaxValueErrorComponent,
        MinLengthErrorComponent,
        MaxLengthErrorComponent,
        OnlyNumbersErrorComponent,
        RequiredErrorComponent,
        LocalDatePipe,
        YearPipe,
        AgePipe,
        FormatSizePipe,
        CurrentValidPipe,
        FormatPersonPipe,
        FormatVoucherPipe,
        FormatVoucherValidityPipe,
        FormatPersonContactPipe,
        FormatAddressPipe,
        ShortDatePipe,
        ShortTimePipe,
        GenerateInputIdPipe,
        TableFilterComponent,
        TableFilterV2Component,
        ButtonComponent,
        CancelButtonComponent,
        ContinueButtonComponent,
        ButtonDeleteComponent,
        SaveButtonComponent,
        ConfirmationComponent,
        FloatingMessageComponent,
        FloatingErrorMessageComponent,
        FloatingWarnMessageComponent,
        InputAddressComponent,
        InputTextAutocompleteComponent,
        InputUserMultiselectComponent,
        MapComponent,
        MapLinkComponent,
        FloatingMessageComponent,
        InputSelectSearchComponent,
        InputDateScrollerComponent,
        PluralizationComponent,
        AvatarComponent,
        UserChipComponent,
        InputRadioYesNoComponent,
        InputMultiSelectComponent,
        InnerModalComponent,
        JoditWrapperComponent,
        SafeHtmlPipe,
        FormatNumberPipe,
        SumItemsPipe,
        CheckOrCrossComponent,
        AuditLogTreeComponent,
        FormArrayWrapComponent,
        StripAndShowComponent,
        FolderFormDataExistsComponent,
    ],
    providers: [
        MapService,
        UserService,
        DialogService,
        DynamicDialogRef,
        DynamicDialogConfig,
        ConfirmBuilder,
        FileService,
    ],
    imports: [
        LocalDatePipe,
        YearPipe,
        AgePipe,
        FormatSizePipe,
        CurrentValidPipe,
        FormatPersonPipe,
        FormatVoucherPipe,
        FormatVoucherValidityPipe,
        FormatPersonContactPipe,
        FormatAddressPipe,
        ShortDatePipe,
        ShortTimePipe,
        GenerateInputIdPipe,
        CommonModule,
        TranslateModule.forChild({ extend: true }),
        StoreModule.forFeature(sharedFeatureKey, sharedReducer),
        StoreModule.forFeature(userFeatureKey, userReducer),
        EffectsModule.forFeature([SharedEffects]),
        AngularSvgIconModule,
        TableModule,
        FormsModule,
        ReactiveFormsModule,
        ButtonModule,
        DialogModule,
        InputNumberModule,
        InputTextModule,
        DropdownModule,
        ConfirmDialogModule,
        CheckboxModule,
        InputTextareaModule,
        MultiSelectModule,
        OverlayPanelModule,
        MenuModule,
        RadioButtonModule,
        ToastModule,
        ChipModule,
        InputSwitchModule,
        ProgressBarModule,
        TimelineModule,
        FileUploadModule,
        CalendarModule,
        SelectButtonModule,
        DividerModule,
        PaginatorModule,
        ListboxModule,
        CardModule,
        TagModule,
        AvatarModule,
        EditorModule,
        TranslateOrPipe,
        AutoCompleteModule,
        InputMaskModule,
        RippleModule,
        SkeletonModule,
        ContextMenuModule,
        SafeHtmlPipe,
        NgxJoditProComponent,
        FormatNumberPipe,
        SumItemsPipe,
        TreeModule,
        DynamicDialogModule,
        EmptyStateComponent,
    ],
})
export class SharedModule {}
