import { TableColumn, TableColumnType } from './table-v2.types';

export const defaultString: TableColumn = {
    name: '',
    type: TableColumnType.String,
};

export const defaultNumber: TableColumn = {
    name: '',
    type: TableColumnType.Number,
    filterable: {
        type: 'number',
    },
};

export const defaultDate: TableColumn = {
    name: '',
    type: TableColumnType.Date,
    filterable: {
        type: 'date',
    },
};

export const defaultDatetime: TableColumn = {
    name: '',
    type: TableColumnType.DateTime,
    filterable: {
        type: 'date',
    },
};

export const defaultAge: TableColumn = {
    name: '',
    type: TableColumnType.Age,
    filterable: {
        type: 'number',
    },
};

export const defaultYear: TableColumn = {
    name: '',
    type: TableColumnType.Year,
    filterable: {
        type: 'number',
    },
};

export const defaultTag: TableColumn = {
    name: '',
    type: TableColumnType.Tag,
    filterable: {
        type: 'select',
        hideOperators: true,
    },
};

export const defaultUserChip: TableColumn = {
    name: '',
    type: TableColumnType.UserChip,
};

export const defaultMapLink: TableColumn = {
    name: '',
    type: TableColumnType.MapLink,
};

export const DEFAULTS: Map<TableColumnType, TableColumn> = new Map<TableColumnType, TableColumn>([
    [TableColumnType.String, defaultString],
    [TableColumnType.Number, defaultNumber],
    [TableColumnType.Date, defaultDate],
    [TableColumnType.DateTime, defaultDatetime],
    [TableColumnType.Age, defaultAge],
    [TableColumnType.Year, defaultYear],
    [TableColumnType.Tag, defaultTag],
    [TableColumnType.UserChip, defaultUserChip],
    [TableColumnType.MapLink, defaultMapLink],
]);
