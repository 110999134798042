import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FilterInput } from '@it2go/data-grid';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import config from '../../../../../config/config';
import { SubscriptionComponent } from '../../../helpers/subscription.component';
import { TimePeriodEnum } from '../../../../content/enum/services/timePeriod.enum';
import { TableColumnFilterable } from '../table-v2/table-v2.types';

export const FILTER_OPS_INT = [
    'EQ',
    'LT',
    'LTE',
    'GT',
    'GTE',
    'BETWEEN',
    'NBETWEEN',
    'NEQ',
];

export const FILTER_OPS_DATE = [
    'EQ',
    'LT',
    'LTE',
    'GT',
    'GTE',
    'BETWEEN',
    'NBETWEEN',
];

@Component({
    selector: 'app-table-filter-v2',
    templateUrl: './table-filter-v2.component.html',
    styleUrls: ['./table-filter-v2.component.scss'],
})
export class TableFilterV2Component extends SubscriptionComponent implements OnInit, OnChanges {
    @Input() column!: string;
    @Input() filter!: boolean | TableColumnFilterable;
    @Input() initial: FilterInput | null = null;
    @Input() filterOps: string[] = ['LIKE', 'STARTS', 'ENDS', 'EQ', 'NEQ'];

    @Output() valueChange = new EventEmitter<FilterInput>();

    protected value: string | null = null;
    protected value2: string | null = null;
    protected date: string | null = null;
    protected date2: string | null = null;
    protected selectValue: string = '';
    protected selectedOp = 'LIKE';
    protected filterable: TableColumnFilterable = { type: 'text' };
    protected debouncer: Subject<string> = new Subject<string>();

    public ngOnInit(): void {
        this.subs.push(
            this.debouncer
                .pipe(debounceTime(config.debounceTime))
                .subscribe(() => {
                    if (this.filterable.type === 'number') {
                        this.value = Number(this.value)
                            ? Number(this.value).toString()
                            : '';
                        this.value2 = Number(this.value2)
                            ? Number(this.value2).toString()
                            : '';
                    }
                    this.valueChange.emit({
                        value: [this.value || '', this.value2 || ''],
                        column: this.column,
                        operator: this.selectedOp,
                    });
                }),
        );

        if (typeof this.filter !== 'boolean') {
            this.filterable = this.filter as TableColumnFilterable;
        }

        switch (this.filterable.type) {
            case 'number':
                this.filterOps = FILTER_OPS_INT;
                break;
            case 'date':
                this.filterOps = FILTER_OPS_DATE;
                break;
            case 'select':
                this.selectedOp = 'EQ';
                this.selectValue = this.filterable.selectOptions
                    ? this.filterable.selectOptions()[0]?.value
                    : '';
                break;
        }
        this.selectedOp = this.filterOps[0];
    }

    public ngOnChanges(changes: any): void {
        if (changes.initial) {
            const values = changes.initial.currentValue;
            if (this.filterable.type === 'date') {
                let d: string | null = values?.value?.[0] || null;
                if (d) {
                    const dd = new Date(d);
                    this.date = `${dd.getDate()}.${dd.getMonth() + 1}.${dd.getFullYear()}`;
                }
                d = values?.value?.[1] || null;
                if (d) {
                    const dd = new Date(d);
                    this.date2 = `${dd.getDate()}.${dd.getMonth() + 1}.${dd.getFullYear()}`;
                }
            }

            this.value = values?.value?.[0] || null;
            this.value2 = values?.value?.[1] || null;
            this.selectedOp = values?.operator || this.filterOps[0];
        }
    }

    public clearValue() {
        this.value = null;
        this.value2 = null;
        this.date = null;
        this.date2 = null;
    }

    protected valueChanged(): void {
        this.debouncer.next('');
    }

    protected updateDate(key: number, date: Date | null) {
        if (date) {
            const d = new Date(date);
            d.setMinutes(d.getMinutes() - d.getTimezoneOffset());
            if (key === 1) {
                this.value = d.toISOString();
            } else {
                this.value2 = d.toISOString();
            }
        } else {
            if (key === 1) {
                this.value = null;
            } else {
                this.value2 = null;
            }
        }
        this.valueChanged();
    }

    protected readonly TimePeriodEnum = TimePeriodEnum;
}
