import { AfterContentInit, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { SubscriptionComponent } from '../../../helpers/subscription.component';

@Component({
    selector: 'app-card',
    templateUrl: 'card.component.html',
    styleUrls: ['card.component.scss'],
})
export class CardComponent extends SubscriptionComponent implements OnInit {

    @Input() title!: string;
    @Output() copyCallback = new EventEmitter<any>();
    @Output() addCallback = new EventEmitter<any>();

    @ContentChild('header', { read: TemplateRef })
    headerTemplate: TemplateRef<any> | undefined;

    @ContentChild('content', { read: TemplateRef })
    contentTemplate: TemplateRef<any> | undefined;

    hasCopy!: boolean;
    hasAdd!: boolean;

    ngOnInit(): void {
        this.hasCopy = this.copyCallback.observed;
        this.hasAdd = this.addCallback.observed;
    }
}
