import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { EmptyState } from './empty-state.types';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-empty-state',
    templateUrl: 'empty-state.component.html',
    styleUrl: 'empty-state.component.scss',
    standalone: true,
    imports: [CommonModule, TranslateModule],
})
export class EmptyStateComponent {
    @Input()
    emptyState: EmptyState = new EmptyState();

    @Input()
    @HostBinding('class.horizontal')
    horizontal = false;
}
