import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatAddress',
    standalone: true,
})
export class FormatAddressPipe implements PipeTransform {
    transform(address?: any | null): string {
        return formatAddress(address);
    }
}

export function formatAddress(address?: any | null): string {
    if (!address) {
        return '';
    } else if (address.name) {
        return `${address.name}, ${address.location}`;
    } else if (address.address) {
        return formatAddress(address.address as any);
    } else if (Array.isArray(address)) {
        return formatAddress(address[0] as any);
    }

    return '';
}
