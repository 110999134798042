<app-input-multi-select
    [options]="$any(store.users$ | async)"
    [form]="form"
    [id]="id"
    [label]="label"
    valueKey="id"
    nameKey="name"
    filterBy="name,surname,id,email"
    [shouldFilter]="true"
    [showClear]="true"
    (filter)="filter($event)"
>
    <ng-template pTemplate="item" let-item="item">
        <div class="flex gap-2">
            <div>{{ item | formatPerson }}</div>
            <div *ngIf="item.name?.length">{{ item.email }}</div>
        </div>
    </ng-template>
    <ng-template pTemplate="selectedItems" let-items="items">
        <div class="flex gap-2">
            <p-chip *ngFor="let item of items" [label]="store.userNames[item.id]"></p-chip>
        </div>
    </ng-template>
</app-input-multi-select>