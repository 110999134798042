import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-strip-and-show',
    templateUrl: './strip-and-show.component.html',
    styleUrl: './strip-and-show.component.scss',
})
export class StripAndShowComponent implements OnInit {
    @Input() public text: string = '';
    @Input() public length: number = 500;
    visible: boolean = false;
    plainText = '';
    html: SafeHtml = '';

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit(): void {
        if (this.text) {
            this.plainText = this.convertHtmlToPlainText();
            this.html = this.bypassSecurityTrustHtml();
        }
    }

    convertHtmlToPlainText(): string {
        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(this.text, 'text/html');
        const plainText = parsedHtml.body.textContent || '';
        return plainText.slice(0, this.length);
    }

    showDialog($event: MouseEvent): void {
        $event.stopPropagation();
        this.visible = true;
    }

    bypassSecurityTrustHtml(): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(this.text);
    }
}
