import { addressFrag } from './common.types';

export const userRoleGridItemFrag = `
    id
    name
`;

export const employmentFrag = `
    employmentTypeName
    id
    organizationId
    serviceId
    size
`;

export const userGridItemFrag = `
    address {
        ${addressFrag}
    }
    email
    id
    mobile
    name
    phone
    surname
    titleAfterName
    titleBeforeName
    icpOfDoctor
    employments {
        ${employmentFrag}
    }
    role {
        ${userRoleGridItemFrag}
    }
    active
    organizations {
        id
    }
    groups {
        id
        code
    }
`;

export const userFrag = `
    address {
        ${addressFrag}
    }
    email
    id
    mobile
    name
    phone
    surname
    titleAfterName
    titleBeforeName
    icpOfDoctor
    employments {
        ${employmentFrag}
    }
    role {
        ${userRoleGridItemFrag}
    }
    active
`;

export const serviceUserFrag = `
    id
    name
    surname
    email
    titleAfterName
    titleBeforeName
`;
