<p-card>
    <ng-template pTemplate="header">
        <ng-container [ngTemplateOutlet]="headerTemplate || defaultHeaderTemplate"></ng-container>
    </ng-template>
    <ng-template pTemplate="content">
        <ng-container [ngTemplateOutlet]="contentTemplate || defaultContentTemplate"></ng-container>
    </ng-template>
    <ng-content></ng-content>
</p-card>

<ng-template #defaultHeaderTemplate>
    <div class="flex justify-content-between align-items-center gap-small">
        <div class="textSubheader flex-1">{{ title }}</div>
        <i *ngIf="hasCopy" class="pi pi-file-import text-primary cursor-pointer" (click)="copyCallback.emit()"></i>
        <p-button *ngIf="hasAdd" icon="pi pi-plus" severity="success" (click)="addCallback.emit()"></p-button>
    </div>
</ng-template>

<ng-template #defaultContentTemplate>
    <app-empty-state></app-empty-state>
</ng-template>
