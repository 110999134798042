export enum OrganizationSettingTypeEnum {
    COLLECTION_CATEGORY = 'collectionCategory',
    DARUJME = 'darujme',
    SPORITELNA = 'sporitelna',
    DONATION_FORM = 'donationForm',
    RAIFFEISEN = 'raiffeisen',
    PASSWORD_RESET = 'passwordReset',
    SYSTEM_SETTINGS = 'systemSettings',
    EMAILING_SETTINGS = 'emailingSettings',
}
