export enum EmploymentGroupEnum {
    SOCIAL_WORKER = 'socialWorker',
    WORKERS_IN_SOCIAL_SERVICES = 'workersInSocialServices',
    MEDICAL_WORKERS = 'medicalWorkers',
    NON_MEDICAL_HEALTH_WORKERS = 'nonMedicalHealthWorkers',
    TEACHING_STAFF = 'teachingStaff',
    MARRIAGE_FAMILY_COUNSELORS = 'marriageFamilyCounselors',
    OTHER_PROF_SOCIAL_WORKERS = 'otherProfSocialWorkers',
    SERVICE_STAFF = 'serviceStaff',
    EXECUTIVES = 'executives',
    ADMINISTRATIVE_STAFF = 'administrativeStaff',
    VOLUNTEERS = 'volunteers',
}
