<form [formGroup]="form">
    <span *ngIf="!hideLabel" class="p-float-label" [class.disabled]="disabled">
        <p-dropdown [id]="id" [formControlName]="id" [filter]="shouldFilter" [virtualScroll]="virtualScroll"
                    [readonly]="disabled"
                    [options]="options" [optionValue]="$any(valueKey)" [optionLabel]="$any(nameKey)"
                    [filterBy]="$any(filterBy)"
                    [group]="group" [optionGroupLabel]="groupNameKey"
                    [showClear]="showClear"
                    (onFilter)="filterCallback($event.filter)"
                    styleClass="w-full"
                    [emptyMessage]="'common.noResults' | translate"
                    [emptyFilterMessage]="'common.noResults' | translate"
                    [appendTo]="appendToBody ? 'body' : ''">
            <ng-template pTemplate="header">
                <div *ngIf="tooManyValues" class="p-2 text-danger">{{ 'common.tooManyResults' | translate }}</div>
            </ng-template>

            <ng-container *ngIf="itemTemplate">
                <ng-template pTemplate="item" let-item>
                    <ng-container *ngTemplateOutlet="itemTemplate; context: { item }"></ng-container>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="!itemTemplate">
                <ng-template pTemplate="item" let-item>
                    {{ translate ? (namePrefix + item[nameKey] | translate) : item[nameKey] }}
                </ng-template>
            </ng-container>

            <ng-container *ngIf="selectedItemTemplate">
                <ng-template pTemplate="selectedItem" let-item>
                    <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { item }"></ng-container>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="!selectedItemTemplate">
                <ng-template pTemplate="selectedItem" let-item>
                    {{ translate ? (namePrefix + item[nameKey] | translate) : item[nameKey] }}
                </ng-template>
            </ng-container>

            <ng-container *ngIf="groupTemplate">
                <ng-template pTemplate="group" let-group>
                    <ng-container *ngTemplateOutlet="groupTemplate; context: { group }"></ng-container>
                </ng-template>
            </ng-container>

            <ng-container *ngIf="!groupTemplate">
                <ng-template pTemplate="group" let-group>
                    {{ namePrefix + group[groupNameKey] | translate }}
                </ng-template>
            </ng-container>
        </p-dropdown>
        <label [for]="id">{{ label | translate }}</label>
    </span>

    <!-- bez labelu -->
    <p-dropdown *ngIf="hideLabel" [id]="id" [filter]="shouldFilter" [virtualScroll]="virtualScroll"
                [formControlName]="id" [options]="options" [optionValue]="valueKey"
                [showClear]="showClear"
                (onFilter)="filterCallback($event.filter)"
                [optionLabel]="nameKey" styleClass="w-full">

        <ng-container *ngIf="itemTemplate">
            <ng-template pTemplate="item" let-item>
                <ng-container *ngTemplateOutlet="itemTemplate; context: { item }"></ng-container>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="!itemTemplate">
            <ng-template pTemplate="item" let-item>
                {{ translate ? (namePrefix + item[nameKey] | translate) : item[nameKey] }}
            </ng-template>
        </ng-container>

        <ng-container *ngIf="selectedItemTemplate">
            <ng-template pTemplate="selectedItem" let-item>
                <ng-container *ngTemplateOutlet="selectedItemTemplate; context: { item }"></ng-container>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="!selectedItemTemplate">
            <ng-template pTemplate="selectedItem" let-item>
                {{ translate ? (namePrefix + item[nameKey] | translate) : item[nameKey] }}
            </ng-template>
        </ng-container>

        <ng-container *ngIf="groupTemplate">
            <ng-template pTemplate="group" let-group>
                <ng-container *ngTemplateOutlet="groupTemplate; context: { group }"></ng-container>
            </ng-template>
        </ng-container>

        <ng-container *ngIf="!groupTemplate">
            <ng-template pTemplate="group" let-group>
                {{ namePrefix + group[groupNameKey] | translate }}
            </ng-template>
        </ng-container>

    </p-dropdown>
</form>
<app-required-error [form]="form" [id]="id"></app-required-error>
