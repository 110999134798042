<div *ngIf="emptyState" class="content">
    <i [class]="emptyState.icon"></i>
    <div class="text-wrapper">
        <div class="first-line-text">
            {{ emptyState.title|translate }}
        </div>
        <div *ngIf="emptyState.text" class="second-line-text">
            {{ emptyState.text|translate }}
        </div>
    </div>
</div>
