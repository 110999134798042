<ng-container *ngIf="text?.length">
    <span [style]="{ cursor: 'pointer' }"  (click)="showDialog($event)">{{ this.plainText }}…</span>
    <p-dialog
        [modal]="true"
        [(visible)]="visible"
        [maximizable]="true"
        (click)="$event.stopPropagation()"
    >
        <div [style]="{ textWrap: 'pretty', minWidth: '250px' }" [innerHTML]="this.html"></div>
    </p-dialog>
</ng-container>
