import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalDatePipe } from './local-date.pipe';
import { SessionService } from '../../../services/session.service';

@Pipe({
    name: 'formatVoucherValidity',
    standalone: true,
})
export class FormatVoucherValidityPipe implements PipeTransform {
    constructor(
        private translate: TranslateService,
        private session: SessionService,
    ) {}

    transform(voucher?: any | null): string {
        const datePipe = new LocalDatePipe(this.session);
        return formatVoucherValidity(this.translate, datePipe, voucher);
    }
}

export function formatVoucherValidity(
    translate: TranslateService,
    datePipe: LocalDatePipe,
    voucher?: any | null,
): string {
    if (!voucher) {
        return '';
    }

    return translate.instant('formats.voucher-validity', {
        from: datePipe.transform(voucher.issueDate, 'dd.MM.yyyy'),
        to: datePipe.transform(voucher.validTo, 'dd.MM.yyyy'),
    });
}
