export class EmptyState {
    icon?: string;
    title?: string;
    text?: string;

    constructor(icon?: string, title?: string, text?: string) {
        this.icon = icon ? icon : 'pi pi-ban';
        this.title = title ? title : 'database.emptyState.title';
        this.text = text ? text : 'database.emptyState.text';
    }
}
