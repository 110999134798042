// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { Operations } from '@it2go/types';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { combineLatest, EMPTY, lastValueFrom, Observable, Subscriber, Subscription, switchMap, take } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
// TODO import z "it2go/types" nefunguje
import { ActionEnum } from '../../../../../../../types/src/authorization/enum/action.enum';
import { ResourceEnum } from '../../../../../../../types/src/authorization/enum/resource.enum';
import { globalApiFailure, globalSuccess } from '../../../../store/global/global.actions';
import {
    isAllowed,
    selectCurrentOrganizationId,
    selectCurrentUserId,
    selectLastAction,
} from '../../../../store/global/global.selectors';
import { selectRouteParam, selectRouteParams } from '../../../../store/global/router.selector';
import { knownRouteParameters, knownRouteParametersKey } from '../../../content/content-routing.params';
import { FilterBuilder } from '../../../content/helper/filter.builder';
import { setTableRefresher, unsetTableRefresher } from '../shared.actions';
import { stripPrefix } from './common';
import {
    AllowedSelects,
    AllSelects,
    Clears,
    Effects,
    HasNextType,
    OnSuccesses,
    outsideParametersKnowOptionsKeys,
    outsideParametersOptions,
    RefreshOn,
    RefreshOnAny,
} from './component-store.factory.base';
import { DonationService } from '../../../../services/donation.service';
import { CollectionService } from '../../../../services/collection.service';
import { DonorService } from '../../../../services/donor.service';
import { ObjectHistoryService } from '../../../../services/object-history.service';
import { WarningService } from '../../../../services/warning.service';
import { ExpectedDonationService } from '../../../../services/expected-donation.service';
import { OrganizationService } from '../../../../services/organization.service';
import { UserService } from '../../../../services/user.service';
import { AclService } from '../../../../services/acl.service';
import { ServiceService } from '../../../../services/service.service';
import { AuthorizationService } from '../../../../services/authorization.service';
import { PersonService } from '../../../../services/person.service';
import { WorkplaceService } from '../../../../services/workplace.service';
import { PersonAddressService } from '../../../../services/person-address.service';
import { SettingsService } from '../../../../services/settings.service';
import { TemplateService } from '../../../crm/service/template.service';
import { contentGlobalSuccess } from '../../../content/content.error';
import { FolderService } from '../../../../services/folder.service';
import { ExpertiseGqlService } from '../../../../services/expertise.gql-service';
import { FileService } from '../../../content/service/file.service';
import { VzpGqlService } from '../../../../services/vzp.gql-service';
import { NursingCareService } from '../../../../services/nursing-care.service';

type ApiSubscribed<T> = {
    [K in keyof T]: T[K] extends (...args: infer P) => Observable<infer R> ? (...args: P) => Subscriber<R> : never;
};

@Injectable()
export abstract class ComponentStoreFactory<
        T extends object,
        Api = DonationService &
            CollectionService &
            DonorService &
            ObjectHistoryService &
            WarningService &
            ExpectedDonationService &
            OrganizationService &
            UserService &
            AclService &
            ServiceService &
            AuthorizationService &
            SettingsService &
            PersonService &
            WorkplaceService &
            PersonAddressService &
            TemplateService &
            FolderService &
            ExpertiseGqlService &
            FileService &
            VzpGqlService &
            NursingCareService,
    >
    extends ComponentStore<T>
    implements OnDestroy
{
    data: AllSelects<T> = {} as AllSelects<T>;
    hasNext: HasNextType<T> = {} as HasNextType<T>;
    clearData: Clears<T> = {} as Clears<T>;
    api: Effects<ApiSubscribed<Api>> = {} as Effects<Api>;
    apiSuccess: OnSuccesses<Api> = {} as OnSuccesses<Api>;
    apiFailure: OnSuccesses<Api> = {} as OnSuccesses<Api>;
    globalStore?: Store;
    routeParam: Record<knownRouteParametersKey, Observable<string>> = {};
    tableRefreshers = [];
    isAllowed: AllowedSelects = {} as AllowedSelects;
    _defaultState: any = {};

    public organizationId = 0;
    public userId = '';

    protected subs: Subscription[] = [];

    private lastLoadedPage: Record<string, number> = {}; // potreba pro infinite scrolly

    constructor(defaultState?: T, store?: Store, ...svcs: any[]) {
        super(defaultState);
        this.globalStore = store;
        this._defaultState = defaultState;

        if (defaultState) {
            Object.keys(defaultState).forEach((key) => {
                const infiniteScrollMatch = /^(.+)InfiniteScroll$/.exec(key);
                if (infiniteScrollMatch && infiniteScrollMatch.length > 1 && infiniteScrollMatch[1] in defaultState) {
                    // field pro itemy z infinite scrollu
                    this.data[key] = () => [];
                    this.clearData[key] = () => {
                        this.patchState({ [key]: null });
                    };

                    const keyOfGridField = infiniteScrollMatch[1];
                    this.lastLoadedPage[keyOfGridField] = 0;
                    this.subs.push(
                        this.data[`${keyOfGridField}$`].subscribe(async (grid) => {
                            if (grid) {
                                const loadedPage = grid.paging.page;
                                if (loadedPage == 1) {
                                    this.data[key] = () => grid.items;
                                } else if (loadedPage > this.lastLoadedPage[keyOfGridField]) {
                                    const currentItems = await this.data[key]();
                                    this.data[key] = () => [...currentItems, ...grid.items];
                                }
                            } else {
                                this.data[key] = () => [];
                            }
                            this.lastLoadedPage[keyOfGridField] = grid?.paging.page ?? 0;
                        }),
                    );
                } else {
                    // field pro obycejny select
                    this.data[`${key}$`] = this.select((state) => state[key]);
                    this.hasNext[`${key}$`] = this.select(
                        (state) =>
                            (state[key]?.['paging']?.['lastPage'] || 0) > (state[key]?.['paging']?.['page'] || 0),
                    );
                    this.data[key] = () => lastValueFrom(this.select((state) => state[key]).pipe(take(1)));
                    this.clearData[key] = () => {
                        this.patchState({ [key]: null });
                    };
                }
            });
        }

        svcs?.forEach((svc) => this.addApi(svc));

        if (store) {
            knownRouteParameters.forEach((param) => {
                this.routeParam[param] = store.select(selectRouteParam(param));
            });

            Object.values(ResourceEnum).forEach((resource) =>
                Object.values(ActionEnum).forEach((action) => {
                    const key = `${resource}.${action}` as Operations;
                    this.isAllowed[key] = store.select(isAllowed(key));
                }),
            );
            this.subs.push(
                store.select(selectCurrentOrganizationId).subscribe((it) => {
                    this.organizationId = it || 0;
                }),
                store.select(selectCurrentUserId).subscribe((it) => {
                    this.userId = it || '';
                }),
            );
        }
    }

    get<Get>(observable: Observable<Get>) {
        return lastValueFrom(observable.pipe(take(1)));
    }

    onRouteChange(
        effect: keyof Effects<Api>,
        parameters: Record<string, knownRouteParametersKey> = {},
        outsideParameters?: outsideParametersOptions,
    ) {
        const outsideParameterObservables = Object.keys(outsideParameters || {}).filter(
            (key) => !outsideParametersKnowOptionsKeys.includes(key),
        );

        this.subs.push(
            combineLatest([
                this.globalStore!.select(selectRouteParams),
                ...outsideParameterObservables.map((key) => outsideParameters[key]),
            ]).subscribe(([routeParams, ...args]) => {
                let ok = true;
                let input = Object.entries(parameters).reduce((acc, [inputKey, routeKey]) => {
                    let val = routeParams[routeKey];
                    ok = ok && val && val != 0;
                    if (!ok) return;

                    if (['id'].includes(inputKey) && !isNaN(Number(val))) {
                        val = Number(val);
                    }
                    acc[inputKey] = val;

                    return acc;
                }, {});
                if (!ok) return;
                let i = 0;
                outsideParameterObservables.forEach((key) => {
                    input![key] = args[i++];
                });
                if (outsideParameters?.asFilter) {
                    input = FilterBuilder.filter(input);
                } else if (outsideParameters?.asSinglePageFilter) {
                    input = FilterBuilder.singlePage(input);
                }

                this.api[effect](input);
            }),
        );
    }

    public doOnActions(refreshCallback: CallableFunction, ...actions: RefreshOnAny[]) {
        this.subs.push(
            this.globalStore!.select(selectLastAction).subscribe((lastAction) => {
                if (actions.includes(lastAction.key)) {
                    refreshCallback(lastAction.data);
                }
            }),
        );
    }

    public registerSuccessMessage(action: RefreshOn<Api>, message: string): void {
        this.subs.push(this.apiSuccess[action]?.subscribe(() => contentGlobalSuccess.next(message)));
    }

    public refreshGrid(gridAction: RefreshOn<Api> | string, ...refreshOn: (RefreshOn<Api> | string)[]) {
        this.tableRefreshers.push(stripPrefix(gridAction));
        this.globalStore!.dispatch(setTableRefresher({ table: stripPrefix(gridAction), actions: refreshOn }));
    }

    public override ngOnDestroy() {
        super.ngOnDestroy();
        this.subs.forEach((sub) => sub.unsubscribe());
        this.tableRefreshers.forEach((table) => this.globalStore!.dispatch(unsetTableRefresher({ table })));
        this.patchState(this._defaultState);
    }

    protected addApi(svc: any) {
        Object.getOwnPropertyNames(Object.getPrototypeOf(svc)).forEach((method) => {
            if (method == 'constructor') return;

            const dataKey = stripPrefix(method);
            const successKey = `${method}Success`; // TODO odebrat
            const effect = this.effect((input$) =>
                input$.pipe(
                    switchMap((input) =>
                        svc[method](input).pipe(
                            tap({
                                next: (result) => {
                                    this.globalStore?.dispatch(
                                        globalSuccess({
                                            input: {
                                                key: method,
                                                data: result,
                                            },
                                        }),
                                    );
                                    // TODO odebrat
                                    if (successKey in this) {
                                        this[successKey](result);
                                    }
                                    this.apiSuccess[method].emit(result);
                                    return this.patchState({
                                        [dataKey]: result,
                                    });
                                },
                            }),
                            catchError((err) => {
                                this.apiFailure[method].emit(err.graphQLErrors?.[0]?.message ?? err);
                                this.globalStore?.dispatch(globalApiFailure({ key: method }));
                                return EMPTY;
                            }),
                        ),
                    ),
                ),
            );

            // Give function a name
            this.api[method] = {
                [method](...args) {
                    return effect.apply(this, args);
                },
            }[method];
            this.apiSuccess[method] = new EventEmitter();
            this.apiFailure[method] = new EventEmitter();
        });
    }
}
