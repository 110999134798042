<ng-container *ngIf="user">
    <p-chip *ngIf="!user.imgSrc$; else imageSrcTpl"
            [label]="fullName"
            [styleClass]="type === UserChipRenderTypeEnum.Client ? 'client' : ''"
            (mouseover)="showDetails($event)"
            (mouseleave)="hideDetails()"
    >
        <div [class]="'flex flex-center font-size-small initials ' + user.sex">
            {{ initials }}
        </div>
    </p-chip>

    <p-overlayPanel *ngIf="type === UserChipRenderTypeEnum.Client" #detailsOverlay [dismissable]="false"
                    (mouseleave)="hideDetails()">
        <ng-template pTemplate="content">
            <div class="flex-column gap-small bg-primary bg-text-primary details-content">
                <div class="flex align-items-center gap-small">
                    <svg-icon name="person-circle"></svg-icon>
                    <div class="font-bold font-size-small">{{ fullName }}</div>
                </div>
                <div *ngIf="user.birthDate" class="flex align-items-center gap-small">
                    <svg-icon name="asterisk"></svg-icon>
                    <div class="font-bold font-size-small">{{ user.birthDate | date: 'd.M.y' }}</div>
                </div>
                <div *ngIf="user.address" class="flex align-items-center gap-small">
                    <svg-icon name="house-door-fill"></svg-icon>
                    <div class="font-bold font-size-small">{{ user.address | formatAddress }}</div>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>

    <ng-template #imageSrcTpl>
        <p-chip
            [label]="fullName"
            [styleClass]="type === UserChipRenderTypeEnum.Client ? 'client' : ''"
            (mouseover)="showDetails($event)"
            (mouseleave)="hideDetails()"
        >
            <img *ngIf="user.imgSrc$ | async as imgSrc; else skeleton" [src]="imgSrc" [alt]="initials">
        </p-chip>
    </ng-template>

    <ng-template #skeleton>
        <p-skeleton shape="circle" size="1.75rem"></p-skeleton>
    </ng-template>
</ng-container>
