<p-dialog [header]="translatePrefix + dialogTitle + 'Title' | translate" [(visible)]="dialogVisible" [modal]="true"
    [draggable]="false" [resizable]="false" [style]="{maxWidth: '1300px'}">
    <ng-container *ngTemplateOutlet="dialog; context: { hide: closeDialog, data: dialogData }"></ng-container>
</p-dialog>

<div class="flex-column" [classList]="containerClasses">
    <div *ngIf="title.length || dialog || showCreate" class="flex justify-content-between mt-2 mb-4">
        <div *ngIf="title.length" class="text-xl">{{ (title ? title : translatePrefix + 'labelMulti' + 'title') |
            translate }}</div>
        <ng-container *ngTemplateOutlet="dialogButton"></ng-container>
        <button *ngIf="!dialogButton && dialog" pButton pRipple icon="pi pi-plus" class="p-button-success add-button"
            (click)="showDialog(true, null)"></button>
        <button *ngIf="showCreate" pButton pRipple icon="pi pi-plus" class="p-button-success add-button"
            (click)="createCallback.emit()"></button>
    </div>

    <ng-container *ngTemplateOutlet="subtitle"></ng-container>

    <div [formGroup]="form" class="flex flex-row my-2 mr-2 search-bar">
        <div class="flex flex-row">
            <button *ngIf="allowFiltering" type="button" pButton pRipple icon="pi pi-filter-slash"
                (click)="clearFilters()" class="mr-2 p-button-outlined" [pTooltip]="'table.clearFiltersExt' | translate"
                tooltipPosition="bottom" [label]="'table.clearFilters' | translate"></button>
            <button *ngIf="showExport" type="button" pButton pRipple icon="pi pi-file" (click)="exportCSV()"
                class="mr-2 p-button-secondary" pTooltip="export CSV" tooltipPosition="bottom"></button>
            <button *ngIf="showExport" type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()"
                class="p-button-secondary mr-2" pTooltip="export XLS" tooltipPosition="bottom"></button>
            <button *ngIf="showExport" type="button" pButton pRipple icon="pi pi-file-pdf" (click)="exportPdf()"
                class="p-button-secondary mr-2" pTooltip="export PDF" tooltipPosition="bottom"></button>
            <p-multiSelect *ngIf="showColumnVisiblity" [options]="columnsVisibilityOptions" [filter]="false"
                formControlName="columns" optionLabel="name">
                <ng-template pTemplate="selectedItems">{{ 'table.visibleColumns' | translate }}</ng-template>
                <ng-template pTemplate="item" let-item>{{ translatePrefix + 'fields.' + item.name | translate
                    }}</ng-template>
            </p-multiSelect>
        </div>
        <div *ngIf="showSearch" class="flex justify-content-end">
            <app-input-search [form]="form"></app-input-search>
        </div>
    </div>

    <p-contextMenu #cm [model]="contextMenu"></p-contextMenu>

    <p-table *ngIf="layoutService.loaderIconName$ | async as loaderIconName" [value]="items || []" [rows]="gridData?.['paging']?.['itemsPerPage'] || 10"
        (sortFunction)="sortChange($event)" [customSort]="true" [rowHover]="true" [loading]="isLoading | async"
        [dataKey]="dataKey" styleClass="p-datatable-gridlines" selectionMode="multiple" [(selection)]="selectedRows"
        (selectionChange)="selectionChange($event)" [reorderableColumns]="true" (onColReorder)="tableReorder($event)"
        [resizableColumns]="true" (onColResize)="columnResize($event)" [contextMenu]="cm"
        [(contextMenuSelection)]="selectedContextRow" [frozenValue]="frozenRows" [scrollable]="true">
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="!hideCheckboxes" style="width: 4rem" pFrozenColumn>
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th *ngFor="let col of frozenColumns" pFrozenColumn>
                    {{ translatePrefix + 'fields.' + col.name | translate }}
                </th>
                <th *ngIf="rowExpansion" style="width: 4rem"></th>
                <th *ngIf="showDetail || dialog">{{ detailHeader? (translatePrefix + detailHeader | translate) : '' }}
                </th>
                <th *ngIf="itemTemplates['link']"></th>

                <ng-container *ngFor="let col of visibleColumns">
                    <th *ngIf="itemTemplates[col.name + 'Header']" [attr.colname]="col.name" pReorderableColumn
                        pResizableColumn [style]="{ width: getColumnSize(col.name) }">
                        <ng-container *ngTemplateOutlet="itemTemplates[col.name + 'Header']"></ng-container>
                    </th>
                    <th *ngIf="col.sortable && !itemTemplates[col.name + 'Header']" [attr.colname]="col.name"
                        [pSortableColumn]="col.name" pReorderableColumn pResizableColumn
                        [style]="{ width: getColumnSize(col.name) }">
                        <p-sortIcon *ngIf="col.sortable" [field]="col.name"></p-sortIcon>
                        {{ translatePrefix + 'fields.' + col.name | translate }}
                    </th>
                    <th *ngIf="!col.sortable && !itemTemplates[col.name + 'Header']" [attr.colname]="col.name"
                        pReorderableColumn pResizableColumn [style]="{ width: getColumnSize(col.name) }">
                        {{ translatePrefix + 'fields.' + col.name | translate }}
                    </th>
                </ng-container>
                <th *ngIf="showDelete">{{ deleteHeader? (translatePrefix + deleteHeader | translate) : '' }}</th>
            </tr>
            <tr *ngIf="allowFiltering">
                <th *ngIf="!hideCheckboxes" style="width: 4rem" pFrozenColumn></th>
                <th *ngFor="let col of frozenColumns" pFrozenColumn></th>
                <th *ngIf="rowExpansion" style="width: 4rem"></th>
                <th *ngIf="showDetail || dialog"></th>
                <th *ngIf="itemTemplates['link']"></th>

                <ng-container *ngFor="let col of visibleColumns">
                    <th *ngIf="{ filter: getFilter(col.name) } as data">
                        <app-table-filter-v2 [id]="'__filter_' + col.name" *ngIf="col.filterable !== undefined"
                            [column]="col.name" [filter]="col.filterable"
                            (valueChange)="filterChange($event)">
                        </app-table-filter-v2>
                    </th>
                </ng-container>
                <th *ngIf="showDelete"></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item let-expanded="expanded" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                <td *ngIf="!hideCheckboxes" [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }" pFrozenColumn>
                    <p-tableCheckbox [pSelectableRow]="item" [value]="item"></p-tableCheckbox>
                </td>
                <td *ngFor="let col of frozenColumns" pFrozenColumn class="frozenIndex"
                    [attr.data-ui-el]="col.name" [pContextMenuRow]="{ column: col.name, item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenColumnContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }">
                    <app-table-cell-content [template]="itemTemplates[col.name]" [column]="col" [item]="item"
                        [rowIndex]="rowIndex"></app-table-cell-content>
                </td>

                <td *ngIf="rowExpansion" [pContextMenuRow]="{ column: 'expansion', item, rowIndex }"
                    (contextmenu)="changeContextMenu(bodyContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                    (click)="rowExpanded.emit({expanded, item})">
                    <button type="button" pButton pRipple [pRowToggler]="item"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td *ngIf="showDetail || dialog" class="tableIconCell"
                    [pContextMenuRow]="{ column: 'detail', item, rowIndex }"
                    (contextmenu)="changeContextMenu(bodyContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }">
                    <p-button icon="pi pi-folder-open" styleClass="p-button-info p-button-text tableIcon"
                        [pTooltip]="translatePrefix + 'editButtonTitle' | translateOr"
                        (click)="showDialog(true, item)"></p-button>
                </td>

                <td *ngIf="itemTemplates['link']"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }">
                    <ng-container *ngTemplateOutlet="itemTemplates.link; context: { item: item }">
                    </ng-container>
                </td>

                <td *ngFor="let col of visibleColumns" [attr.data-ui-el]="col.name"
                    [ngStyle]="{ 'max-width': getColumnSize(col.name) }"
                    [pContextMenuRow]="{ column: col.name, item, rowIndex }"
                    (contextmenu)="changeContextMenu(bodyContextMenu)"
                    [ngClass]="{'stripped': isStripped(item, col.name, rowIndex), 'btNone': matchPreceding(item, col.name, rowIndex) && false }">
                    <app-table-cell-content [template]="itemTemplates[col.name]" [column]="col" [item]="item"
                        [rowIndex]="rowIndex"></app-table-cell-content>
                </td>

                <td *ngIf="showDelete" class="tableIconCell" [pContextMenuRow]="{ column: 'delete', item, rowIndex }"
                    (contextmenu)="changeContextMenu(bodyContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }">
                    <p-button icon="pi pi-trash" styleClass="p-button-danger p-button-text inline-button tableIcon"
                        [pTooltip]="translatePrefix + 'deleteButtonTitle' | translateOr"
                        (click)="deleteCallback.emit(item); refreshData()" *ngIf="showDelete"></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="frozenbody" let-item let-expanded="expanded" let-rowIndex="rowIndex">
            <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                <td pFrozenColumn>
                    <p-tableCheckbox [pSelectableRow]="item" [value]="item"></p-tableCheckbox>
                </td>

                <td *ngFor="let col of frozenColumns" pFrozenColumn class="frozenIndex2"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                    [attr.data-ui-el]="col.name">
                    <app-table-cell-content [template]="itemTemplates[col.name]" [column]="col" [item]="item"
                        [rowIndex]="rowIndex"></app-table-cell-content>
                </td>

                <td *ngIf="rowExpansion" [pContextMenuRow]="{ column: 'expansion', item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }">
                    <button type="button" pButton pRipple [pRowToggler]="item"
                        class="p-button-text p-button-rounded p-button-plain"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td *ngIf="showDetail || dialog" class="tableIconCell"
                    [pContextMenuRow]="{ column: 'detail', item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }">
                    <p-button icon="pi pi-folder-open" styleClass="p-button-info p-button-text tableIcon"
                        [pTooltip]="translatePrefix + 'editButtonTitle' | translateOr"
                        (click)="showDialog(true, item)"></p-button>
                </td>

                <td *ngIf="itemTemplates.link">
                    <ng-container *ngTemplateOutlet="itemTemplates.link; context: { item: item }">
                    </ng-container>
                </td>

                <!-- Column-->
                <td *ngFor="let col of visibleColumns" [ngStyle]="{ 'max-width': getColumnSize(col.name) }"
                    [pContextMenuRow]="{ column: col.name, item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }"
                    [attr.data-ui-el]="col.name">
                    <app-table-cell-content [template]="itemTemplates[col.name]" [column]="col" [item]="item"
                        [rowIndex]="rowIndex"></app-table-cell-content>
                </td>

                <td *ngIf="showDelete" class="tableIconCell" [pContextMenuRow]="{ column: 'delete', item, rowIndex }"
                    (contextmenu)="changeContextMenu(frozenRowContextMenu)"
                    [ngClass]="{'stripped': (rowIndex % 2) && !selectedRows.includes(item) }">
                    <p-button icon="pi pi-trash" styleClass="p-button-danger p-button-text inline-button tableIcon"
                        [pTooltip]="translatePrefix + 'deleteButtonTitle' | translateOr"
                        (click)="deleteCallback.emit(item)" *ngIf="showDelete"></p-button>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="footer" *ngIf="footerTemplate">
            <ng-container *ngTemplateOutlet="footerTemplate; context: {items, visibleColumns}"></ng-container>
        </ng-template>

        <ng-template pTemplate="rowexpansion" let-item>
            <tr class="w-full">
                <td colspan="999">
                    <ng-container *ngTemplateOutlet="rowExpansion; context: {item}"></ng-container>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="loadingicon">
            <svg-icon [name]="loaderIconName"></svg-icon>
        </ng-template>
    </p-table>

    <ng-container *ngIf="!!gridData">
        <p-paginator *ngIf="{ summary: tableSummary(gridData) } as data"
            [first]="((gridData?.['paging']?.['page'] || 1) - 1) * (gridData?.['paging']?.['itemsPerPage'] || 10)"
            [rows]="gridData?.['paging']?.['itemsPerPage'] || 10" [totalRecords]="gridData?.['paging']?.['total'] || 0"
            [rowsPerPageOptions]="rowsPerPageOptions"
            (onPageChange)="pageChange($event)"
            [showCurrentPageReport]="true"
            [currentPageReportTemplate]="data.summary">
        </p-paginator>
    </ng-container>
</div>
