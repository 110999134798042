import { Component } from '@angular/core';
import { LayoutService } from '../../../../layout/services/layout.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
    constructor(public layout: LayoutService) {}
}
