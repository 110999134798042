import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from '../../../services/session.service';
import { FormatVoucherValidityPipe } from './format-voucher-validity.pipe';

@Pipe({
    name: 'formatVoucher',
    standalone: true,
})
export class FormatVoucherPipe implements PipeTransform {
    constructor(
        private translate: TranslateService,
        private session: SessionService,
    ) {}

    transform(voucher?: any | null, detailed: boolean = true): string {
        const validityPipe = new FormatVoucherValidityPipe(
            this.translate,
            this.session,
        );
        return formatVoucher(this.translate, validityPipe, detailed, voucher);
    }
}

export function formatVoucher(
    translate: TranslateService,
    validityPipe: FormatVoucherValidityPipe,
    detailed: boolean,
    voucher?: any | null,
): string {
    if (!voucher) {
        return '';
    }

    const info = translate.instant('formats.voucher', {
        number: voucher.sequenceNumber,
        icpOfDoctor: voucher.doctor?.icpOfDoctor || '',
    });

    if (detailed) {
        return `${info}, ${validityPipe.transform(voucher)}`;
    }

    return info;
}
