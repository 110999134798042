import { Injectable } from '@angular/core';
import { UserGridItemObject, UserGridObject } from '@it2go/types';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { ComponentStoreFactory } from '../../../store/common/component-store.factory';
import { UserService } from '../../../../../services/user.service';
import { formatPerson } from '../../../pipes/format-person.pipe';

interface State {
    users: UserGridObject | null,
}

const defaultState: State = {
    users: null,
};

@Injectable()
export class InputUserMultiselectStore extends ComponentStoreFactory<State, UserService> {

    users$ = this.data.users$.pipe(map(
        (users) => users?.['items'] || []),
    );

    userNames: Record<string, string> = {};

    constructor(
        svc: UserService,
        store: Store,
    ) {
        super(defaultState, store, svc);

        this.subs.push(
            this.users$.subscribe((users) => {
                users.forEach((it: UserGridItemObject) => {
                    this.userNames[it.id] = formatPerson(it);
                });
            }),
        )
    }

}
