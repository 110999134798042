import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { UserChip, UserChipRenderTypeEnum } from './user-chip.component.types';

@Component({
    selector: 'app-user-chip',
    templateUrl: './user-chip.component.html',
    styleUrls: ['./user-chip.component.scss'],
})
export class UserChipComponent implements OnChanges {
    @Input() user!: UserChip;
    @Input() renderType?: UserChipRenderTypeEnum;

    @ViewChild('detailsOverlay') detailsOverlay: OverlayPanel | undefined;

    type?: UserChipRenderTypeEnum;

    initials!: string;
    fullName!: string;

    public ngOnChanges(): void {
        if (this.user) {
            this.type = this.renderType ? this.renderType : this.user.renderType ? this.user.renderType : UserChipRenderTypeEnum.User;
            this.initials = `${this.user.name?.slice(0, 1)}${this.user.surname?.slice(0, 1)}`;
            this.fullName = `${(this.user.titleBeforeName || '')} ${this.user.name || ''} ${this.user.surname || ''} ${(this.user.titleAfterName || '')}`.trim() || this.user.email || '';
            this.fullName.replaceAll('\s+', ' ');
        }
    }

    showDetails(event: MouseEvent) {
        if (this.detailsOverlay) {
            this.detailsOverlay.show(event);
        }
    }

    hideDetails() {
        if (this.detailsOverlay) {
            this.detailsOverlay.hide();
        }
    }

    protected readonly UserChipRenderTypeEnum = UserChipRenderTypeEnum;
}
